<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountLayout",
};
</script>

<style lang="scss" scoped>
.page-header {
  background-image: url('~@/assets/img/backgrounds/account.jpg');
}
</style>
